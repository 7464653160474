import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import zhHans from '@/locale/zhHans.json'
import zhHant from '@/locale/zhHant.json'
import ja from '@/locale/ja.json'
import en from '@/locale/en.json'
import ko from '@/locale/ko.json'
import { SessionStorage } from 'storage-manager-js'

Vue.use(Vuetify)
Vue.use(VueI18n)

const languageList = {
	EN: 'en',
	JA: 'ja',
	KO: 'ko',
	'ZH-CN': 'zhHans',
	'ZH-TW': 'zhHant',
}

const language = SessionStorage.get('LANGUAGE') || 'EN'

export const i18n = new VueI18n({
	locale: languageList[language],
	silentFallbackWarn: true,
	messages: {
		zhHans: zhHans,
		zhHant: zhHant,
		ja: ja,
		en: en,
		ko: ko,
	},
})

export const vuetify = new Vuetify({
	theme: {
		dark: true,
		themes: {
			light: {
				primary: '#EB6930',
			},
			dark: {
				primary: '#EB6930',
			},
		},
		options: {
			themeCache: {
				get: key => localStorage.getItem('THEME'),
				set: (key, value) => localStorage.setItem('THEME', 'dark'),
			},
		},
	},
	lang: {
		t: (key, ...params) => i18n.t(key, params),
	},
	breakpoint: {
		mobileBreakpoint: 'sm', // 这个值等于960
	},
})

import Vue from 'vue'
import Vuex from 'vuex'
import { SessionStorage } from 'storage-manager-js'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		ADDRESS: SessionStorage.get('ADDRESS') || null,
		liquidity_authorization_list: [],
		currency_obj: {
			BNB: {
				name: 'BNB',
				address: '',
				balance: 0,
				logo: 'http://placekitten.com/g/400/400?BNB',
			},
			JAM: {
				name: 'JAM',
				address: '0x7a155957A97a34352f69af573f9E607663ADA8A7',
				balance: 0,
				logo: 'http://placekitten.com/g/400/400?JAM',
			},
			TEST: {
				name: 'TEST',
				address: '0x7df8f2af893ffbee0d2db2070c7f42406550b739',
				balance: 0,
				logo: 'http://placekitten.com/g/400/400?TEST',
			},
			SAUNA: {
				name: 'SAUNA',
				address: '0xEA399e6FeeEd62e08e118B79F53f4a1ad34B6b6f',
				balance: 0,
				logo: 'http://placekitten.com/g/400/400?SAUNA',
			},
		},
		BNB_balance: 0,
		my_card_list: [],
		card_nav_list: [
			{
				title: 'ALL',
				value: 0,
				type: 0,
			},
			{
				title: 'N',
				value: 0,
				type: 1,
			},
			{
				title: 'R',
				value: 0,
				type: 2,
			},
			{
				title: 'SR',
				value: 0,
				type: 3,
			},
			{
				title: 'SSR',
				value: 0,
				type: 4,
			},
			{
				title: 'UR',
				value: 0,
				type: 5,
			},
			{
				title: 'GOD',
				value: 0,
				type: 9,
			},
		],
		language: SessionStorage.get('LANGUAGE') || 'EN',
		overlay: false,
	},
	mutations: {
		setADDRESS (state, address) {
			state.ADDRESS = address
			state.currency_obj.BNB.address = address
			SessionStorage.set('ADDRESS', address)
		},
		setLiquidityAuthorization (state, status) {
			state.liquidity_authorization_list.push(status)
		},
		setCurrencyBalance (state, { name, balance }) {
			state.currency_obj[name].balance = balance
		},
		setBNBBalance (state, data) {
			state.BNB_balance = data
		},
		setMyCardList (state, data) {
			state.my_card_list = data
		},
		SET_LANGUAGE (state, lang) {
			state.language = lang
		},
		SET_OVERLAY (state, overlay) {
			state.overlay = overlay
		},
	},
	actions: {},
	getters: {
		is_connected: state => state.ADDRESS !== null,
		currency_list: state => {
			const arr = []
			for (const key in state.currency_obj) {
				arr.push(state.currency_obj[key])
			}
			return arr
		},
	},
	modules: {},
	// plugins: [createPersistedState()],
})

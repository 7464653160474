import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/index',
	},
	{
		path: '/index',
		name: 'Index',
		component: () => import('@/views/Index'),
	},
	{
		path: '/app',
		component: () => import('@/views/View'),
		children: [
			{
				path: '',
				component: () => import('@/views/Dashboard'),
			},
			{
				path: 'MiningPool',
				component: () => import('@/views/MiningPool'),
			},
			{
				path: 'FarmingPool',
				component: () => import('@/views/FarmingPool'),
			},
			{
				path: 'BonusesPool',
				component: () => import('@/views/BonusesPool'),
			},
			{
				path: 'Airdrop',
				component: () => import('@/views/Airdrop'),
			},
			{
				path: 'Referral',
				component: () => import('@/views/Referral'),
			},
			{
				path: 'Buring',
				component: () => import('@/views/Buring'),
			},
			{
				path: 'NFTMarket',
				component: () => import('@/views/Market/NFTMarket'),
			},
			{
				path: 'Buy',
				component: () => import('@/views/Market/Buy'),
			},
			{
				path: 'AuctionList',
				component: () => import('@/views/Market/AuctionList'),
			},
			{
				path: 'AuctionDetail',
				component: () => import('@/views/Market/AuctionDetail'),
			},
			{
				path: 'Sell',
				component: () => import('@/views/Market/Sell'),
			},
			{
				path: 'Auction',
				component: () => import('@/views/Market/Auction'),
			},
			{
				path: 'BuyUR',
				name: 'BuyUR',
				component: () => import('@/views/Game/BuyUR'),
			},
			{
				path: 'Game',
				redirect: '/app/Game/BuyBox',
				component: () => import('@/views/Game/Game'),
				children: [
					{
						path: 'BuyBox',
						name: 'BuyBox',
						component: () => import('@/views/Game/BuyBox'),
					},
					{
						path: 'MyNFT',
						name: 'MyNFT',
						component: () => import('@/views/Game/MyNFT'),
						children: [
							{
								path: '',
								name: 'MyNFTList',
								component: () => import('@/views/Game/MyNFT/List'),
							},
							{
								path: 'NftDetails',
								name: 'NftDetails',
								component: () => import('@/views/Game/MyNFT/NftDetails'),
							},
						],
					},
					{
						path: 'Leaderboard',
						name: 'Leaderboard',
						component: () => import('@/views/Game/Leaderboard'),
					},
					{
						path: 'Display',
						name: 'Display',
						component: () => import('@/views/Game/Display'),
					},
					{
						path: 'CombineGuide',
						name: 'CombineGuide',
						component: () => import('@/views/Game/CombineGuide'),
					},
				],
			},
			{
				path: 'FAQ',
				component: () => import('@/views/FAQ'),
			},
		],
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	},
})

export default router

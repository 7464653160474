<template>
  <div class="app">
		<router-view/>
		<v-overlay :value="overlay">
			<v-progress-circular
					indeterminate
					size="64"
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		...mapState(['overlay']),
	},
	mounted () {
		// if (process.env.NODE_ENV === 'production') {
		// 	if (window.ethereum.chainId !== '0x38') {
		// 		window.ethereum.request({
		// 			method: 'wallet_switchEthereumChain',
		// 			params: [{ chainId: '0x38' }],
		// 		}).then(res => {
		// 			console.log(res)
		// 		})
		// 	}
		// } else {
		// 	if (window.ethereum.chainId !== '0x61') {
		// 		window.ethereum.request({
		// 			method: 'wallet_switchEthereumChain',
		// 			params: [{ chainId: '0x61' }],
		// 		}).then(res => {
		// 			console.log(res)
		// 		})
		// 	}
		// }
	},
}
</script>

<style lang="scss">
@media (max-width: 960px) {
	.foot {
		font-size: 12px;
	}
}
.v-btn:not(.v-btn--round).v-size--large {
	height: 60px !important;
}

.v-btn--rounded {
	border-radius: 20px !important;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 8px;
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: #eb6930;
	border-radius: 8px;
}
::-webkit-scrollbar-track {
	//box-shadow: inset 0 0 5px #483f5a;
	border-radius: 10px;
	background: transparent;
}
.v-application a {
	color: inherit;
}
</style>

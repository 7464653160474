<template>
<div class="t-btn" :class="{'t-btn__outlined': outlined, 't-btn__loading': loading}" @click="handleClick" :style="{'width': width, 'height': height, 'background-color': color, fontSize}" v-ripple>
  <span v-if="loading">{{ loadingText }}<v-icon large>mdi-dots-horizontal</v-icon></span>
  <slot v-else/>
</div>
</template>

<script>
export default {
	name: 'TButton',
	props: {
		loading: {
			default: false,
			type: Boolean,
		},
		loadingText: {
			default: 'Pending',
			type: String,
		},
		width: {
			default: '100%',
			type: String,
		},
		height: {
			default: '60px',
			type: String,
		},
		color: {
			default: '#EB6930',
			type: String,
		},
		fontSize: {
			default: '24px',
			type: String,
		},
		outlined: {
			default: false,
			type: Boolean,
		},
		disabled: {
			default: false,
			type: Boolean,
		},
	},
	methods: {
		handleClick (event) {
			if (!this.loading) {
				this.$emit('click', event)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.t-btn {
  height: 60px;
  background: #EB6930;
  border-radius: 20px;
  font-weight: 900;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
	&__loading {
		background-color: rgba(235, 105, 48, 0.5) !important;
	}
	&__outlined {
		border: 1px solid #EB6930;
		background-color: transparent !important;
		color: #EB6930;
	}
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { vuetify, i18n } from './plugins/vuetify'
// import vuescroll from 'vuescroll'
import Web3 from 'web3'
import './assets/css/public.scss'
import './assets/css/pc.scss'
import './assets/css/mobile.scss'
import { TButton, currency_popup } from './components'
import JSBI from 'jsbi'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import moment from 'moment'
import Clipboard from 'v-clipboard'

const options = {
	// You can set your default options here
}

Vue.use(Toast, options)

// Vue.use(vuescroll, {
// 	ops: {
// 		bar: {
// 			background: '#5c6b78',
// 			size: '4px',
// 			keepShow: true,
// 		},
// 	},
// })

let web3 = null
const ethereum = window.ethereum || null
if (ethereum) {
	web3 = new Web3(ethereum)
} else if (window.web3) {
	web3 = window.web3
} else {
	const provider = new Web3.providers.HttpProvider('ws://127.0.0.1:8545')
	web3 = new Web3(provider)
}

Vue.prototype.web3 = web3
Vue.prototype.ethereum = ethereum
Vue.prototype.JSBI = JSBI
Vue.prototype.$moment = moment

// Vue.prototype.Web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:8545'))

Vue.config.productionTip = false
Vue.component('TButton', TButton)
Vue.component('CurrencyPopup', currency_popup)

Vue.use(Clipboard)
new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
}).$mount('#app')

<template>
	<v-dialog v-model="show" :max-width="maxWidth" @input="currency_popup_input">
		<div class="c-dialog">
			<div class="c-dialog__head">
				<span>Select a token</span>
					<v-btn @click="close_popup" icon>
					<v-icon color="#EB6930">mdi-close</v-icon>
				</v-btn>
			</div>
			<div class="c-dialog__content">
				<div class="currency-list__item" v-for="(item, index) in currency_list" :key="index" @click="select_currency(item)">
					<div class="currency-list__item--left">
						<img :src="item.logo" alt="">
						<span>{{ item.name }}</span>
					</div>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'currency_popup',
	data () {
		return {
			show: false,
			selectedItem: '',
			items: [
				{ text: 'Real-Time', icon: 'mdi-clock' },
				{ text: 'Audience', icon: 'mdi-account' },
				{ text: 'Conversions', icon: 'mdi-flag' },
			],
		}
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		maxWidth: {
			type: String,
			default: '520px',
		},
	},
	watch: {
		value (val) {
			this.show = val
		},
	},
	computed: {
		...mapGetters(['currency_list']),
	},
	methods: {
		close_popup () {
			this.$emit('close')
		},
		currency_popup_input (val) {
			if (!val) {
				this.$emit('close')
			}
		},
		select_currency (item) {
			this.$emit('change', item)
		},
	},
}
</script>

<style lang="scss" scoped>
.currency-list {
	&__item {
		&--left {
			img {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				margin-right: 15px;
			}
			display: flex;
			align-items: center;
		}
		cursor: pointer;
		margin-bottom: 15px;
		padding: 10px 0;
	}
}
</style>
